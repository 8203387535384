<template>
	<div style="background-color:#E6F5E4 ;">
		<div style="width: 100%;">
			<img :src="questionnairePath" />
		</div>
		<div class="memberCont" style="margin-top: -24px;">

			<div style="margin: 20px;background-color: #ffffff;">
				<el-form :model="questionForm" status-icon ref="questionForm" label-width="100px">

					<div style="font-size: 20px;font-weight: 700;color: #000000;text-align: center;padding: 30px 0;">
						{{questionList.fTitle}}
						<div style="margin-top: 5px;color: #666666;font-size: 12px;font-weight: normal">
							问卷调查时间：{{questionList.fBeginTime}}~{{questionList.fEndTime}}</div>
					</div>

					<div style="font-weight: 400;color: #009b64;padding-left: 30px;padding-bottom: 10px;">
						感谢您参与我们的调研，您的参与对我们来说非常重要，我们会更好地为您提供服务。
					</div>

					<div style="border:dashed 1px rgb(220, 220, 220);margin:0 30px;margin-bottom: 20px;">

					</div>

					<div v-for="(item,index) in formDataDetail" style="padding: 0 30px;">

						<div style="padding-bottom: 15px;">
							<span style="font-weight: bold;">{{index+1}}. {{item.fSubject}}</span>
							<span style="padding-left: 5px;">( {{item.fSubjectClass}} )</span>
						</div>

						<div v-for="it in item.fOption">
							<!-- 单选 -->
							<div v-if="item.fSubjectClassID==1" style="padding-bottom: 10px;padding-left:15px;">
								<el-radio-group class="radioStyle" v-model="item.fContent"
									@change="handleRadioChange(it,index)">
									<el-radio :label="it.fOptionID" :disabled="fStatus==1">{{it.fOptionNumber}}.
										{{it.fOption}}
									</el-radio>
								</el-radio-group>
							</div>

							<!-- 多选 -->
							<div v-if="item.fSubjectClassID==2" style="padding-bottom: 10px;padding-left:15px;">
								<el-checkbox-group class="checkboxStyle" v-model="item.chectData"
									@change="handleCheckedCitiesChange(it,index)">
									<el-checkbox :label="it.fOptionID" :disabled="fStatus==1">
										{{it.fOptionNumber}}. {{it.fOption}}
									</el-checkbox>
								</el-checkbox-group>
							</div>
						</div>

						<!-- 问答 -->
						<div v-if="item.fSubjectClassID==3" style="padding-bottom: 10px;padding-left:15px;">
							<el-input @input="handleInputChange()" :disabled="fStatus==1" type="textarea" autosize
								placeholder="请输入内容" v-model="arr[index].fContent" :autosize="{ minRows: 6, maxRows: 6}"
								style="width: 533px;">
							</el-input>
						</div>
					</div>

					<el-form-item v-if="fStatus != 1" style="padding-bottom: 65px;padding-top: 10px;">
						<el-button v-if="fStatus != 1" @click="save()" plain type="primary"
							style="width: 120px;height: 40px;border-radius: 2px !important;border-color: #009B64;">
							保存问卷
						</el-button>

						<el-button v-if="fStatus != 1" type="primary" @click="open()"
							style="background-color: #009B64;width: 120px;height: 40px;border-radius: 2px !important;border-color: #009B64;">
							提交问卷
						</el-button>
					</el-form-item>
				</el-form>

			</div>
		</div>
	</div>
</template>

<script>
	import SERVER from '../../config/config.json'

	export default {
		data() {
			return {
				questionnairePath: '',
				imageUrl: '',
				loading: false, //加载
				questionList: {},
				formDataDetail: [], //答卷数据

				questionForm: {},
				arr: [{
					fContent: ''
				}],

				questionnaire: [], //用户答过题的数据
				fQuestionnaireParticipant: [], //用户答卷数据
				fStatus: '', //用户是否已答卷状态
				fExecBillID: '',
				btnStatus: 0,
			};
		},
		created() {

		},
		mounted() {
			this.getQuestionInvestigation();
		},
		methods: {

			//更新问答题视图
			handleInputChange() {
				this.$forceUpdate()
			},

			//更新单选题视图
			handleRadioChange(e, index) {
				console.log('e', e.fOptionID)
				console.log('index', index)

				this.$forceUpdate()
				this.arr[index].fContent = e.fOptionID
			},

			//多选
			handleCheckedCitiesChange(value, index) {
				console.log('多选value', value)

				let checkedData = []

				for (let l = 0; l < this.arr[index].chectData.length; l++) {
					checkedData.push({
						fOptionID: this.arr[index].chectData[l],
						fRemark: this.arr[index].fRemark,
						fContent: "",
					})
				}

				this.arr[index].checkboxList = checkedData

				// console.log('checkedData',checkedData)
				// console.log(this.arr)

				this.$forceUpdate();
			},

			//获取当前生效的问卷
			getQuestionInvestigation() {
				this.loading = true
				this.ApiRequestPost('api/mall/auxiliary/questionnaire/get-curret-questionnaire').then(res => {
					console.log('getQuestionInvestigation', res)

					// 先查是否有活动问卷
					if (res.obj) {
						this.questionList = res.obj

						this.questionnairePath = res.obj.fWebContentPicPath;
						this.questionList.fBeginTime = this.getDate(this.questionList.fBeginTime)
						this.questionList.fEndTime = this.getDate(this.questionList.fEndTime)

						this.formDataDetail = res.obj.fSubject
						this.arr = res.obj.fSubject
						this.arr.forEach(i => {
							i.chectData = [];
							i.checkboxList = [];
						})

						// 有活动问卷就去查用户提交状态
						this.getQuestionStatus(res.obj.fBillID);
					} else {
						this.$message({
							message: '暂无数据',
							type: 'warning'
						});
					}

					console.log('初始化', this.arr)

				}, error => {
					this.loading = false
				});
			},

			//用户当前是否已经提交过问卷
			getQuestionStatus(fBillID) {
				// this.loading = true
				this.ApiRequestPost('api/mall/auxiliary/questionnaire-exec/get-by-curret', {
					id: fBillID
				}).then(res => {
					console.log('getQuestionStatus', res)
					if (res.obj) {

						if (res.obj.fQuestionnaireParticipant.length == 0) {
							console.log('用户问卷数据为空')
							return
						}

						if (res.obj.fStatus == 0 || !res.obj.fStatus) {
							this.btnStatus = 1
						} else {
							this.btnStatus = 0
						}

						this.fStatus = res.obj.fStatus //fStatus=1用户提交了问卷，fStatus=0用户保存了问卷
						this.questionnaire = res.obj.fQuestionnaireParticipant //获取到的用户提交/保存的问卷数据
						this.fExecBillID = res.obj.fExecBillID

						for (let i = 0; i < this.questionnaire.length; i++) {
							for (let k = 0; k < this.arr.length; k++) {
								if (this.arr[k].fSubjectID == this.questionnaire[i].fSubjectID) {
									if (this.arr[k].fSubjectClassID == 1) {

										this.arr[k].fContent = this.questionnaire[i].fQuestionnaireResult[0]
											.fOptionID + ''

									}
									if (this.arr[k].fSubjectClassID == 2) {
										let idArray = []
										let idArray2 = []
										for (let t = 0; t < this.questionnaire[i]
											.fQuestionnaireResult.length; t++) {
											idArray.push(this.questionnaire[i].fQuestionnaireResult[t].fOptionID +
												'')

											idArray2.push({
												fOptionID: this.questionnaire[i].fQuestionnaireResult[t]
													.fOptionID + '',
												fRemark: this.arr[k].fRemark,
												fContent: "",
											})
										}
										this.arr[k].chectData = idArray
										this.arr[k].checkboxList = idArray2
									}
									if (this.arr[k].fSubjectClassID == 3) {
										this.arr[k].fContent = this.questionnaire[i]
											.fQuestionnaireResult[0].fContent
									}
								}
							}
						}
					}
				}, error => {
					// this.loading = false
				});
			},

			// 保存
			save() {
				console.log('arr', this.arr)

				let fQuestionnaireParticipant = []
				for (let i = 0; i < this.arr.length; i++) {
					//单选题
					if (this.arr[i].fSubjectClassID === 1) {
						this.arr[i].fQuestionnaireParticipant = [{
							fOptionID: this.arr[i].fContent,
							fRemark: this.arr[i].fRemark,
							fContent: "",
						}]
					}
					//多选题
					if (this.arr[i].fSubjectClassID === 2) {
						this.arr[i].fQuestionnaireParticipant = this.arr[i].checkboxList
					}
					//问答题
					if (this.arr[i].fSubjectClassID === 3) {
						this.arr[i].fQuestionnaireParticipant = [{
							fOptionID: '-1',
							fRemark: this.arr[i].fRemark,
							fContent: this.arr[i].fContent,
						}]
					}

					fQuestionnaireParticipant.push({
						fSubjectID: this.arr[i].fSubjectID,
						fRemark: this.arr[i].fRemark,
						fQuestionnaireResult: this.arr[i].fQuestionnaireParticipant,
					})

				}

				let obj = {}
				if (this.fExecBillID) {
					obj.fExecBillID = this.fExecBillID
				}
				obj.fBillID = this.questionList.fBillID
				obj.fRemark = this.questionList.fRemark
				obj.fQuestionnaireParticipant = fQuestionnaireParticipant

				console.log('obj', obj)
				// return

				this.loading = true
				this.ApiRequestPost('api/mall/auxiliary/questionnaire-exec/create', obj).then(res => {
					this.loading = false
					this.fExecBillID = res.obj.fExecBillID
					// this.$router.go(-1)
					// this.$router.push({
					// 	path: '/QuestionnaireInvestigation',
					// 	query: '',
					// });
					// this.getQuestionInvestigation()
				}, error => {
					this.loading = false
				});
			},

			// 打开提交时的提示框
			open() {
				this.$confirm('您将提交问卷，确认提交吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.submitForm()
				}).catch(() => {
					console.log('用户取消提交答卷')
				});
			},

			//提交答卷
			submitForm() {
				console.log('arr', this.arr)

				let fQuestionnaireParticipant = []
				for (let i = 0; i < this.arr.length; i++) {
					//单选题
					if (this.arr[i].fSubjectClassID === 1) {
						this.arr[i].fQuestionnaireParticipant = [{
							fOptionID: this.arr[i].fContent,
							fRemark: this.arr[i].fRemark,
							fContent: "",
						}]
					}
					//多选题
					if (this.arr[i].fSubjectClassID === 2) {
						this.arr[i].fQuestionnaireParticipant = this.arr[i].checkboxList
					}
					//问答题
					if (this.arr[i].fSubjectClassID === 3) {
						this.arr[i].fQuestionnaireParticipant = [{
							fOptionID: '-1',
							fRemark: this.arr[i].fRemark,
							fContent: this.arr[i].fContent,
						}]
					}

					fQuestionnaireParticipant.push({
						fSubjectID: this.arr[i].fSubjectID,
						fRemark: this.arr[i].fRemark,
						fQuestionnaireResult: this.arr[i].fQuestionnaireParticipant,
					})

				}

				let obj = {}
				if (this.fExecBillID) {
					obj.fExecBillID = this.fExecBillID
				}
				obj.id = -1
				obj.fBillID = this.questionList.fBillID
				obj.fRemark = this.questionList.fRemark
				obj.fQuestionnaireParticipant = fQuestionnaireParticipant

				console.log('obj', obj)
				// return

				this.loading = true
				this.ApiRequestPost('api/mall/auxiliary/questionnaire-exec/submit', obj).then(res => {
					this.loading = false
					this.$router.go(-1); //返回上一层
					// this.$router.push({
					// 	path: '/PersonalContent',
					// 	query: '',
					// });
				}, error => {
					this.loading = false
				});
			},
		},
	}
</script>


<style lang="scss" scoped>
	.el-button--primary.is-plain {
		color: #009b64;
		background: #009b6424;
		border-color: #009b6424;
	}

	.radioStyle ::v-deep {
		.el-radio__inner {
			border-radius: 10%;
		}
	}

	::v-deep #app .el-checkbox__inner {
		border-radius: 1px !important;
	}

	// .checkboxStyle ::v-deep{
	// 	#app .el-checkbox__inner {
	// 	    border-radius: 1px;
	// 	}

	// 	.el-checkbox__inner {
	// 	    border-radius: 1px;
	// 	}
	// }


	.memberCont {
		background-color: #E6F5E4;
		/* width: 100%; */
		width: 1100px;
		margin: 10px auto;
		position: relative;

		.gray-top {
			width: 100%;
			height: 400px;
			background-color: #292929;
			border-radius: 20px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 40px;

		}
	}
</style>
